import { RETURN_GUARANTEE_PRODUCT_HANDLE } from './constants'

export const addReturnGuarantee = (items, cart, returnGuaranteeId) => {
  const guaranteeItem = { variantId: returnGuaranteeId, quantity: 1 }
  return [
    ...(returnGuaranteeId &&
    !cart?.lines?.edges?.some(({ node }) => node?.merchandise?.id === returnGuaranteeId)
      ? [guaranteeItem]
      : []),
    ...items,
  ]
}

export const shouldRemoveReturnGuarantee = (items) => {
  const returnGuaranteeHandle = RETURN_GUARANTEE_PRODUCT_HANDLE

  return (
    (items.length === 1 &&
      items[0]?.node?.merchandise?.product?.handle === returnGuaranteeHandle) ||
    items
      ?.filter((item) => item?.node?.merchandise?.product?.handle !== returnGuaranteeHandle)
      .every(
        (item) =>
          item?.node?.merchandise?.product?.handle === 'skylight-plus' ||
          item?.node?.merchandise?.product?.handle === 'calendar-skylight-plus'
      )
  )
}
