import styled from 'styled-components'
import { darken, lighten } from 'polished'

export const PlusButton = styled.button`
  -webkit-appearance: none;
  text-decoration: none;
  background-color: ${(props) => props.color};
  color: white;
  border: none;
  padding: 16px 48px;
  border-radius: 28px;
  line-height: 1.625;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => darken(0.2, props.color)};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.3, props.color)}`};
  }
  font-family: 'FilsonPro';
  line-height: 23px;
  letter-spacing: 0.09px;
  font-size: 16px;
`
