import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const PlusFeatureContainer = styled.div`
  background-color: ${(props) => props.theme.blueLight};
  padding: 0px 0px 32px;
`

export const Container = styled.div`
  width: 90%;
  margin: auto;
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`
export const Header = styled.h1`
  font-family: 'P22MackinacProBook';
  color: ${(props) => props.theme.blueDark};
  text-align: center;
  font-size: 40px;
  line-height: 48px;
  padding: 40px 0px 0px;
  margin: 0px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 26px;
    line-height: 32px;
    padding: 40px 0px 32px;
    margin: 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 90%;
    margin: auto;
  }
`

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin: 40px 0px;
  grid-template-areas: 'text image';
  &:nth-child(odd) {
    grid-template-areas: 'image text';
  }
  @media (max-width: ${breakpoints.m}px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'image'
      'text';
    margin: 0px;
    gap: 0px;
    &:nth-child(odd) {
      grid-template-areas:
        'image'
        'text';
    }
  }
`
export const ImageContainer = styled.div`
  width: 100%;
  grid-area: image;
  display: ${(props) => (props.mobile ? 'none' : 'block')};
  @media (max-width: ${breakpoints.s}px) {
    display: ${(props) => (props.mobile ? 'block' : 'none')};
  }
`
export const TextContainer = styled.div`
  margin: auto;
  grid-area: text;
  @media (max-width: ${breakpoints.m}px) {
    margin: 16px 0px 32px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 90%;
    margin: 16px auto 32px;
  }
`
export const Title = styled.h2`
  font-family: 'P22MackinacProBook';
  color: ${(props) => props.theme.blueDark};
  font-size: 32px;
  line-height: 40px;
  margin: 0px;
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
    font-size: 28px;
    line-height: 32px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 20px;
  }
`
export const Text = styled.p`
  font-size: 18px;
  font-weight: 425;
  line-height: 28px;
  margin: 0px;
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }
`
export const Disclosure = styled.p`
  font-family: 'FilsonPro';
  width: 340px;
  margin: auto;
  text-align: center;
  font-size: 14px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 12px;
    width: 90%;
  }
`
