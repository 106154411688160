import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { Trans } from 'gatsby-plugin-react-i18next'

import Analytics from 'src/utils/Analytics'
import theme from 'src/utils/styles/theme'
import StoreContext from '../../context/StoreContext'
import { PlusButton } from './styles'

const GetPlusButton = ({ product }) => {
  const productInfo = product.variants[0]
  const { addToCart } = useContext(StoreContext)
  const [submitting, setSubmitting] = useState(false)
  const handleAddToCart = async (variantId) => {
    if (!submitting) {
      setSubmitting(true)
      Analytics.track(
        `Added ${Analytics.trackingConstants[product.handle] || product.handle} to Cart`
      )
      await addToCart([{ variantId, quantity: 1 }])
      navigate('/cart/')
    }
  }
  return (
    <>
      <PlusButton
        color={theme.blueDark}
        type="button"
        disabled={submitting}
        onClick={() => handleAddToCart(productInfo.shopifyId, 1)}
      >
        <Trans>{submitting ? 'Adding Plus...' : 'Get Plus'}</Trans>
      </PlusButton>
    </>
  )
}

GetPlusButton.propTypes = {
  product: PropTypes.object.isRequired,
}

export default GetPlusButton
